import connect from "@vkontakte/vk-bridge";
import { isAdmin } from "spycat-core/client/lib/window";
import { APP_HEIGHT } from "features/app/constants";

connect.send("VKWebAppInit", {});
connect.send("VKWebAppResizeWindow", { width: 1000, height: APP_HEIGHT });

const isHashContainsOffer = /#offer-[a-z0-9]+/.test(window.location.hash);

if (isHashContainsOffer) {
  console.log("start load offer app");
  import("./app/appOffer").then(() => console.info("offer app loaded"));
} else if (!isAdmin) {
  console.log("start load promo app");
  import("./app/maybeAdminAppInPromo").then(() => console.info("promo app loaded"));
} else {
  console.log("start load admin app");
  import("./app/appAdmin").then(() => console.info("admin app loaded"));
}

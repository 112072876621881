import connect from "@vkontakte/vk-bridge";
import {getConfig} from "spycat-core/client/lib/config";

//
function decodeUriWithTry(url){
  try{
    return decodeURIComponent(url);
  }
  catch (e){
    return url
  }
}

const reduceHandler = (acc, item, i) => {
  if (i === 0 && !item.includes("=")) return { ...acc, shortId: parseInt(item, 10), shortValue: item };
  const [key, value] = decodeUriWithTry(item).split("=");
  return key ? { ...acc, [key]: value } : acc;
};

export const queryParams = window.location.search
  .replace("?", "")
  .split("&")
  .reduce(reduceHandler, {});

export const isDesktop = ["desktop_web"].includes(queryParams.vk_platform);
export const isMobile = !isDesktop;
export const isAndroid = ["mobile_android", "mobile_android_messenger"].includes(queryParams.vk_platform);
export const isIos = ["mobile_iphone", "mobile_iphone_messenger"].includes(queryParams.vk_platform);

export const isAdmin = queryParams.vk_viewer_group_role === "admin";

export const getHashParams = () =>
  window.location.hash
    .replace("?", "&")
    .replace("#", "")
    .split("&")
    .reduce(reduceHandler, {});

export const currentVkUserId = parseInt(queryParams.vk_user_id, 10);
export const currentVkGroupId = parseInt(getHashParams().godMode || queryParams.vk_group_id, 10);
export const appId = parseInt(queryParams.vk_app_id, 10);
export const appInCatalog = appId === 7039615

export const setHashParams = location => {
  connect.send("VKWebAppSetLocation", { location: location.toString() });
};

export const getScreenWidth = () => {
  const res = queryParams.vk_platform === "desktop_web" ? 1000 : document.body.clientWidth;
  return res || 1000;
};

export const getAppContext = () => {
  try {
    const isOpenedFromStory = queryParams.vk_ref && queryParams.vk_ref.substr(0, 5) === "story";

    // return {"groupVkId":183559112,"pageId":147}

    const content = queryParams.vk_ref.split("_")[4];
    // const content = "story5802374_456239606_story_1_eyZxdW90O2dyb3VwVmtJZCZxdW90OzoxOTMyMTEzMywmcXVvdDtwYWdlSWQmcXVvdDs6MzU4MzN9".split("_")[4];
    let res = content ? JSON.parse(atob(content).replace(/&quot;/g, '"')) : {};

    console.info('! getAppContext', isOpenedFromStory, res)
    // костыль для обработки ситуации, когла сторис запостили с десктопа§ и в нее не передался контекст
    if (isOpenedFromStory && !res.groupVkId){
      console.info('rewrite')
      const saboConf = getConfig().specProjects.saboJune2022
      console.info('saboConf', saboConf)
      res = {"groupVkId":saboConf.groupVkId,"pageId":saboConf.storyPages[0]}
    }

    console.info('cont', res)
    return res;

  } catch (error) {
    console.info('!! err', error)
    return {};
  }
};

export function setHeight(height) {
  if(connect.supports('VKWebAppResizeWindow')) connect.send("VKWebAppResizeWindow", { width: 1000, height });
}

export function setWindowSize(width, height) {
  if(connect.supports('VKWebAppResizeWindow')) connect.send("VKWebAppResizeWindow", { width: width, height });
}

export function getCurrentBaseUrl(){
  let res = window.APP_BASE_URL
  if (!res){
    res = window.location.protocol + '//' + window.location.host + '/'
  }
  return res
}